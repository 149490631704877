// More info from Optimizely
// https://docs.developers.optimizely.com/feature-experimentation/docs/sdk-reference-guides

import React, { useEffect, useState } from 'react'
import {
  createInstance,
  OptimizelyProvider,
  useDecision,
  setLogLevel,
  setLogger,
  enums,
  OptimizelyDecideOption,
} from '@optimizely/react-sdk'
import { getCookie, getNestedVals, getUrlParamByName, logger } from '@ilc-technology/cefcom-utils'

export const isRunningOnQa = () => {
  if (typeof window === 'undefined') {
    return false
  }
  return window.location.origin.startsWith('https://qa')
}
const isDocAvailable = () => typeof document !== 'undefined'
const useDevKey = isRunningOnQa() || process.env.OPTIMIZELY_USE_DEV === 'true'
const sdkKey = useDevKey ? process.env.OPTIMIZELY_ID_DEV : process.env.OPTIMIZELY_ID
const optimizelyClient = createInstance({ sdkKey })

// Check user is in Audience
const isUserInAudience = (decisionInfo) => {
  try {
    return (
      decisionInfo.reasons.filter((a) => {
        a = a.toLowerCase()
        return (
          a.startsWith('decision_service') &&
          a.includes('audiences') &&
          !a.includes('everyone else') &&
          a.includes('true')
        )
      }).length > 0
    )
  } catch (error) {
    logger(decisionInfo, 'DECISION INFO', error.message, 'err')
  }
}

// On Decision handler
const onDecision = ({ type, userId, attributes, decisionInfo }) => {
  // Add a DECISION Notification Listener for type FLAG
  if (type === 'flag' && isDocAvailable() && isUserInAudience(decisionInfo)) {
    // Access information about feature, for example, key and enabled status
    logger(decisionInfo, '[CEFCOM OPTIMIZELY]', 'Submit decision info to the Data Layer', 'info')
    // Send data to analytics provider here
    window['dataLayer'] = window['dataLayer'] || []
    window['dataLayer'].push({
      event: 'optimizely-decision-fe',
      'optimizely-flagKey': decisionInfo.flagKey,
      'optimizely-ruleKey': decisionInfo.ruleKey,
      'optimizely-variationKey': decisionInfo.variationKey,
    })
  }
}

// Bind notifications
const bindNotifications = () => {
  if (isDocAvailable()) {
    // Remove all Notification Listeners of a certain type
    optimizelyClient.notificationCenter.clearNotificationListeners(
      enums.NOTIFICATION_TYPES.DECISION,
    )
    // Then bind the notification on the Decision
    optimizelyClient.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      onDecision,
    )
  }
}

const Decision = ({ setHasOnFlag, config, children, optimizelyClient }) => {
  const [decision, clientReady] = useDecision(config.flagKey, {
    autoUpdate: true,
    decideOptions: [OptimizelyDecideOption.INCLUDE_REASONS],
  })
  // const variationKey = decision.variationKey

  // did decision fail with a critical error?
  // if (variationKey === null) {
  //   logger(decision, '[OPTIMIZELY]', `decision error: ${decision['reasons']}`, 'err')
  // }

  // Assemble children with props
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { optimizely: { config, decision, optimizelyClient } }),
  )

  // Expose the Optimizely Client to the window for Analytics
  if (isDocAvailable()) {
    window['optimizely_client'] = optimizelyClient
    logger(
      { optimizelyClient, decision, isDEV: useDevKey, sdkKey },
      '[CEFCOM]',
      'Optimizely applied',
      'success',
    )
  }

  return <>{childrenWithProps}</>
}

export default (props) => {
  const sitename = process.env.SITENAME
  // let projectId = 'cefcom-' + sitename
  const { config } = props
  const { centralData } = config
  const { slug } = centralData
  const [isClientReady, setIsClientReady] = useState(false)
  useEffect(() => {
    if (isDocAvailable()) {
      setIsClientReady(true)

      // Log levels
      if (getUrlParamByName('optimizely-debug') === 'true') {
        setLogLevel('debug')
      } else {
        setLogger(null)
      }
      // reloads the clickmap
      // @ts-ignore
      window.EFClickMapActivator.init()
    }
  }, [])

  // Experience
  const experience = getNestedVals(
    centralData,
    ['pageConfig', slug, 'page', 'pageTags', 'tagExperience'],
    null,
  )

  if (!isClientReady) {
    return <>{props.children}</>
  }
  // Bind the notifications
  bindNotifications()

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{
        id: getCookie('optimizelyEndUserId') || 'default_user',
        attributes: {
          market: getCookie('mc') || centralData.mc.toUpperCase(),
          url: slug,
          experience,
          sitename,
        },
      }}
    >
      <Decision
        // setHasOnFlag={setHasOnFlag}
        config={config}
        optimizelyClient={optimizelyClient}
        {...props}
      />
    </OptimizelyProvider>
  )
}
